<template>
<div>
  <b-button variant="success" v-b-modal.editType>Edit Type</b-button>
  <b-modal
    id="editType"
    ref="modal"
    title="Edit Type"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"  
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div class="col-12 mb-2 p-0" >
          <b-button variant="success" @click="addRowHandler">Add Type</b-button>
      </div>
      <b-table
        id="fullTable"
        :fields="fields"
        :items="typeSettings"
        small
        bordered
        responsive
      >
        <template #cell(typeName)="data">
          <b-form-input type="text" v-model="data.item.typeName" :style="{ backgroundColor: data.item.backColor, color: data.item.foreColor}" />
        </template>
        <template #cell(backColor)="data">
          <b-form-input type="text" v-model="data.item.backColor"/>
        </template>
        <template #cell(foreColor)="data">
          <b-form-input type="text" v-model="data.item.foreColor"/>
        </template>
        <template #cell(edit)="data">
          <b-button @click="deleteRowHandler(data)" class="ml-1 mt-1 btn-sm">
            <span><feather type="trash-2"></feather></span>
          </b-button>
        </template>

      </b-table>
    </form>
  </b-modal>
  </div>
</template>

<script>
    export default {
        name: 'TypeEditor',
        props: ['typeSettings'],
        components: {
        },
        data() {
          return {
            state: false,
            validMsg: "Name is required",
            fields: [
              {
                  key:"typeName",
                  //sortable: true,
              },
              {
                  key:"backColor",
              },
              {
                  key:"foreColor",                
              },
              {
                  key:"edit",
              }
            ],
          }
        },
        mounted() {
        },
        methods: {
          checkFormValidity() {
          //todo check emptyness and uniqueness
          const valid = this.$refs.form.checkValidity()
          this.state = valid
          return valid;
          },
          resetModal() {
            this.state = null;
            this.validMsg = '';

          },
          handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
          },
          handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
              return
            }

            this.$emit('change', this.typeSettings);
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('editType')
            })
          },
          deleteRowHandler(data) {
            const indexOfObject = this.typeSettings.findIndex(object => 
            { return object.id === data.item.id;});
            this.typeSettings.splice(indexOfObject, 1);
          },
          addRowHandler() {
            let id = this.typeSettings.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
            this.typeSettings.unshift({ id:id, typeName: 'GOLD'+id, backColor:'black', foreColor:'gold' });
            console.log(this.typeSettings);

          },
        },
    }
</script>