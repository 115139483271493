<template>
  <div id="app">
    <TopMenu/>
    <AlertBox :alert="alert"/>
      <div class="container-fluid bg-dark" >
        <div class="row bg-dark mb-1" >
          <div class="col-lg-2 col-md-4" >
            <b-form-select :disabled="profiles.length==0" v-model="currentServer" :options="serverOptions" class="bg-dark text-light"></b-form-select>
          </div>
          <div class="col-lg-4 col-md-4 ">
            <TypeEditor @change="onChangeTypeSettings" :typeSettings='typeSettings' v-show='isEditable'/>
          </div> 
          <div class="col-lg-4 col-md-1"></div>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="fullTable"
        ></b-pagination>
        <b-table
            id="fullTable"
            :fields="fields"
            :items="displayRecords"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="currentPage"
            :per-page="perPage"
            small
            bordered
            dark
            responsive
        >
         <template #cell(serverName)="data">
            {{ findServerName(data.item.serverId) }}
          </template>
          <template #cell(symbol)="data">
            {{ data.item.symbol  }}
          </template>
          <template #cell(type)="data">
            <b-form-select :disabled="!data.item.isEdit" v-model="data.item.typeId" :options="typeOptions" 
            :style="getColorCell(data.item.typeId)">
            </b-form-select>
          </template>
          <template #cell(pipLim)="data">
            <b-form-input v-if="data.item.isEdit" type="number" v-model="data.item.pipLim"/>
            <span v-else>{{data.item.pipLim}}</span>
          </template>
          <template #cell(lpLim)="data">
            <b-form-input v-if="data.item.isEdit" type="number" v-model="data.item.lpLim"/>
            <span v-else>{{data.item.lpLim}}</span>
          </template>
          <template #cell(spreadLim)="data">
            <b-form-input v-if="data.item.isEdit" type="number" v-model="data.item.spreadLim"/>
            <span v-else>{{data.item.spreadLim}}</span>
          </template>
          <template #cell(timeout)="data">
            <b-form-input v-if="data.item.isEdit" type="number" v-model="data.item.timeout"/>
            <span v-else>{{data.item.timeout}}</span>
          </template>
          <template #cell(subSymbols)="data">
            <SubSymbol  @change="onChangeSubSymbol" v-if="data.item.isEdit" :subSymbols='data.item.subSymbols' :serverRecords='serverRecords' 
            :symbolSettings='symbolSettings' :settingId='data.item.id' :isEdit='data.item.isEdit'/>
            <span v-else>{{data.item.subSymbols.length}}</span>
          </template>
          <template #cell(edit)="data">
            <b-button @click="editRowHandler(data)" class="ml-1 mt-1 btn-sm">
              <span v-if="!data.item.isEdit"><feather type="settings"></feather></span>
              <span v-else><feather type="check"></feather></span>
            </b-button>
            <b-button @click="deleteRowHandler(data)" v-if="data.item.isEdit" class="ml-1 mt-1  btn-sm">
              <span><feather type="trash-2"></feather></span>
            </b-button>
          </template>
        </b-table>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import TypeEditor from '../components/TypeEditor.vue'
import SubSymbol from '../components/SubSymbol.vue'
import AlertBox from '../components/AlertBox.vue'

export default {
  name: 'App',
  components: {
    TopMenu,
    TypeEditor,
    SubSymbol,
    AlertBox
},
  data() {
      return {
          isEditable: true,
          profiles: [],
          symbolSettings : [],
          typeSettings : [],
          serverRecords: [],
          currentServer :0,
          sortBy: 'serverName',
          sortDesc: true,
          fields: [
            {
                key:"serverName",
                sortable: true,
                formatter: (value, key, item) => {
                  return item.serverId;
                },
                sortByFormatted: true,
            },
            {
                key:"symbol",
                sortable: true,
            },
            {
                key:"type",
            },
            {
                key:"pipLim",
            },
            {
                key:"lpLim",
            },
            {
                key:"spreadLim",
            },
            {
                key:"timeout",
            },
            {
                key:"subSymbols",
            },
            {
                key:"edit",
            }
          ],
          alert: {
            time: '',
            text: '',
            variant: 'info'
          },
          perPage: 50,
          currentPage: 1
      }
  },
  async mounted() {
    this.filterUser();
    await this.fetchServerRecords();
    await this.fetchTypeSettings();
    await this.fetchSymbolSettings();
    await this.fetchProfiles();
  },
  computed: {
    displayRecords() {
      return this.currentServer ? this.symbolSettings.filter(symbolSetting=>symbolSetting.serverId == this.currentServer) : this.symbolSettings;
    },
    serverOptions() {
      let options = this.serverRecords.map(server => ( {value: server.id, text: server.serverName} ));
      options.unshift({value:0, text: "ALL"});
      return options;
    },
    typeOptions() {
      return this.typeSettings.map(s => ({ value:s.id, text: s.typeName }));
    },
    rows() {
      return this.displayRecords.length;
    }
  },
  methods: {
    filterUser() {
      if(this.$store.getters.getSession.user.name != 'admin') {
        this.fields = this.fields.filter(field => field.key != 'edit');
        this.isEditable = false;
      }
    },
    async fetchServerRecords() {
      await this.$store.dispatch('fetchServerRecords').then((res) => {
        if(res.status == 200) {
          this.serverRecords = this.$store.getters.getServerRecords.map(item => ({...item, isEdit: false}));
        } else this.alert = {text:'failed to get server records, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchSymbolSettings() {
      await this.$store.dispatch('fetchSymbolSettings').then((res) => {
        if(res.status == 200) {
          let id=1;
          this.symbolSettings = this.$store.getters.getSymbolSettings.map(setting => ({...setting, id: id++, isEdit: false}));
        } else this.alert = {text:'failed to get Symbol Settings, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchProfiles() {
      await this.$store.dispatch('fetchProfiles').then((res) => {
        if(res.status == 200) {
          this.profiles = this.$store.getters.getProfiles;
        } else this.alert = {text:'failed to get Profiles, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchTypeSettings() {
      await this.$store.dispatch('fetchTypeSettings').then((res) => {
        if(res.status == 200) {
          this.typeSettings = this.$store.getters.getTypeSettings;
        } else this.alert = {text:'failed to get Type Settings, reson=' + res.data, variant: 'danger'};
      });
    },
    updateSymbolSettings() {
        this.$store.dispatch('updateSymbolSettings', this.symbolSettings).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Symbol Settings successfully updated', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    updateTypeSettings() {
        this.$store.dispatch('updateTypeSettings', this.typeSettings).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Type Settings successfully updated', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    editRowHandler(data) {
      data.item.isEdit = !data.item.isEdit;
      if(!data.item.isEdit) {
        this.updateSymbolSettings();
      }
    },
    deleteRowHandler(data) {
      if(confirm("Delete this record?"))
      {
        const indexOfObject = this.symbolSettings.findIndex(object => 
        { return object.id === data.item.id;});
        this.symbolSettings.splice(indexOfObject, 1);
        this.updateSymbolSettings();
      }
    },
    findServerName(serverId) {
      let found = this.serverRecords.find(server=> server.id == serverId);
      if(found != undefined)
      {
        return found.serverName;
      }
      else return serverId;
    },
    getColorCell(typeId) {
      let found = this.typeSettings.find(s=>s.id == typeId);
      if(found)
      {
        return { backgroundColor: found.backColor, color: found.foreColor};
      }
      else return {};

    },
    onChangeSubSymbol(data) {
      let found = this.symbolSettings.find(s=> data.id == s.id)
      if(found) {
          found.subSymbols = data.settings;
      }
      else{
        console.log('invalid data in onChangeSubSymbol, data=');
        console.log(data);
      }
    },
    onChangeTypeSettings(data) {
      this.typeSettings = data;
      this.updateTypeSettings();
    },
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>